import { Box } from '@insd47/graytools-library';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { useWindowDimensions, sizeInterpolater } from '../../../utilities';

import Notebook from '../../elements/Main/Notebook.png';
import Notebook2x from '../../elements/Main/Notebook@2x.png';
import Notebook3x from '../../elements/Main/Notebook@3x.png';
import Pen from '../../elements/Main/Pen.png';
import Pen2x from '../../elements/Main/Pen@2x.png';
import Pen3x from '../../elements/Main/Pen@3x.png';
import Phone from '../../elements/Main/Phone.png';
import Phone2x from '../../elements/Main/Phone@2x.png';
import Phone3x from '../../elements/Main/Phone@3x.png';
import Ruler from '../../elements/Main/Ruler.png';
import Ruler2x from '../../elements/Main/Ruler@2x.png';
import Ruler3x from '../../elements/Main/Ruler@3x.png';
import Shapes from '../../elements/Main/Shapes.png';
import Shapes2x from '../../elements/Main/Shapes@2x.png';
import Shapes3x from '../../elements/Main/Shapes@3x.png';
import Timer from '../../elements/Main/Timer.png';
import Timer2x from '../../elements/Main/Timer@2x.png';
import Timer3x from '../../elements/Main/Timer@3x.png';

type ElementType = 'notebook' | 'pen' | 'phone' | 'ruler' | 'shapes' | 'timer';

// motion components
const MotionBox = motion(Box);

function MainBG() {
  const { scrollY } = useScroll();
  const { viewportHeight, viewportWidth } = useWindowDimensions();
  const ElementScale = viewportWidth < 600 ? viewportWidth / 600 : 1;

  const NotebookMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -150]);
  const PhoneMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -200]);
  const PenMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -300]);
  const RulerMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -200]);
  const ShapesMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -600]);
  const TimerMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94 + 230], [0, -400]);

  const transitionConfig = { type: 'spring', stiffness: 300, damping: 30, mass: 0.2 };

  const variant = (elementType: ElementType) => ({
    hidden: {
      y: sizeInterpolater(
        ['notebook', 'pen', 'phone', 'ruler', 'shapes', 'timer'],
        [
          'calc(100vh - 16%)',
          'calc(100vh - 70%)',
          'calc(100vh - 20%)',
          'calc(100vh - 40%)',
          'calc(100vh - 88%)',
          'calc(100vh - 49%)'
        ],
        elementType
      ),
      opacity: 0.3
    },
    visible: {
      opacity: 1,
      y: 0
    }
  });

  return (
    <AnimatePresence>
      <MotionBox
        key="notebook"
        initial="hidden"
        animate="visible"
        variants={variant('notebook')}
        transition={{ ...transitionConfig, delay: 0 }}
        style={{
          position: 'absolute',
          left: '63%',
          top: '16%',
          scale: ElementScale,
          y: NotebookMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="notebook"
          src={Notebook}
          srcSet={`${Notebook}, ${Notebook2x} 2x, ${Notebook3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
      <MotionBox
        key="pen"
        initial="hidden"
        animate="visible"
        variants={variant('pen')}
        transition={{ ...transitionConfig, delay: 0.2 }}
        style={{
          position: 'absolute',
          left: '76%',
          top: '70%',
          scale: ElementScale,
          y: PenMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="pen"
          src={Pen}
          srcSet={`${Pen}, ${Pen2x} 2x, ${Pen3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
      <MotionBox
        key="phone"
        initial="hidden"
        animate="visible"
        variants={variant('phone')}
        transition={{ ...transitionConfig, delay: 0.05 }}
        style={{
          position: 'absolute',
          left: '25%',
          top: '20%',
          scale: ElementScale,
          y: PhoneMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="phonoe"
          src={Phone}
          srcSet={`${Phone}, ${Phone2x} 2x, ${Phone3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
      <MotionBox
        key="ruler"
        initial="hidden"
        animate="visible"
        variants={variant('ruler')}
        transition={{ ...transitionConfig, delay: 0.1 }}
        style={{
          position: 'absolute',
          left: '66%',
          top: '40%',
          scale: ElementScale,
          y: RulerMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="ruler"
          src={Ruler}
          srcSet={`${Ruler}, ${Ruler2x} 2x, ${Ruler3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
      <MotionBox
        key="shapes"
        initial="hidden"
        animate="visible"
        variants={variant('shapes')}
        transition={{ ...transitionConfig, delay: 0.25 }}
        style={{
          position: 'absolute',
          left: '18%',
          top: '88%',
          scale: ElementScale,
          y: ShapesMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="shapes"
          src={Shapes}
          srcSet={`${Shapes}, ${Shapes2x} 2x, ${Shapes3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
      <MotionBox
        key="timer"
        initial="hidden"
        animate="visible"
        variants={variant('timer')}
        transition={{ ...transitionConfig, delay: 0.15 }}
        style={{
          position: 'absolute',
          left: '90%',
          top: '49%',
          scale: ElementScale,
          y: TimerMotion,
          alignItems: 'center',
          justifyContent: 'center',
          willChange: 'transform'
        }}
      >
        <img
          alt="timer"
          src={Timer}
          srcSet={`${Timer}, ${Timer2x} 2x, ${Timer3x} 3x`}
          style={{ position: 'absolute' }}
        />
      </MotionBox>
    </AnimatePresence>
  );
}

export default MainBG;
