import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@insd47/graytools-library';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme="light">
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
