import { Box } from '@insd47/graytools-library';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../../../utilities';

import Cup from '../../elements/When/Cup.png';
import Cup2x from '../../elements/When/Cup@2x.png';
import Cup3x from '../../elements/When/Cup@3x.png';
import File from '../../elements/When/File.png';
import File2x from '../../elements/When/File@2x.png';
import File3x from '../../elements/When/File@3x.png';
import Idea from '../../elements/When/Idea.png';
import Idea2x from '../../elements/When/Idea@2x.png';
import Idea3x from '../../elements/When/Idea@3x.png';
import Mongle1 from '../../elements/When/Mongle1.png';
import Mongle12x from '../../elements/When/Mongle1@2x.png';
import Mongle13x from '../../elements/When/Mongle1@3x.png';
import Mongle2 from '../../elements/When/Mongle2.png';
import Mongle22x from '../../elements/When/Mongle2@2x.png';
import Mongle23x from '../../elements/When/Mongle2@3x.png';

// motion components
const MotionBox = motion(Box);

export default function WhenBG() {
  const { scrollY } = useScroll();
  const { viewportHeight, viewportWidth } = useWindowDimensions();
  const ElementScale = viewportWidth - 48 < 480 ? (viewportWidth - 48) / 480 : 1;
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollStartPoint, setScrollStartPoint] = useState(0);

  const getScrollStartPoint = () => {
    const scrollPos = contentRef.current
      ? window.pageYOffset + contentRef.current.getBoundingClientRect().top
      : 0;
    const startPoint = scrollPos - viewportHeight;

    setScrollStartPoint(startPoint);
  };

  useLayoutEffect(() => {
    getScrollStartPoint();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getScrollStartPoint);

    return () => window.removeEventListener('resize', getScrollStartPoint);
  }, []);

  const IdeaMotion = useTransform(
    scrollY,
    [scrollStartPoint, scrollStartPoint + viewportHeight + 200],
    [100, -200]
  );
  const FileMotion = useTransform(
    scrollY,
    [scrollStartPoint, scrollStartPoint + viewportHeight + 200],
    [300, -260]
  );
  const CupMotion = useTransform(
    scrollY,
    [scrollStartPoint, scrollStartPoint + viewportHeight + 200],
    [100, -120]
  );
  const Mongle1Motion = useTransform(
    scrollY,
    [scrollStartPoint, scrollStartPoint + viewportHeight + 200],
    [60, -80]
  );
  const Mongle2Motion = useTransform(
    scrollY,
    [scrollStartPoint, scrollStartPoint + viewportHeight + 200],
    [100, -60]
  );

  const transitionConfig = { type: 'spring', stiffness: 300, damping: 30, mass: 0.2 };

  return (
    <Box
      ref={contentRef}
      style={{
        width: '480px',
        height: '476px',
        margin: '0 auto',
        transformOrigin: 'top left',
        transform: `scale(${ElementScale})`
      }}
    >
      <AnimatePresence>
        <MotionBox
          key="idea"
          transition={{ ...transitionConfig }}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 2,
            top: '80px',
            left: '218px',
            y: IdeaMotion
          }}
        >
          <img alt="idea" src={Idea} srcSet={`${Idea}, ${Idea2x} 2x, ${Idea3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="file"
          transition={{ ...transitionConfig }}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 2,
            top: '183px',
            y: FileMotion
          }}
        >
          <img alt="file" src={File} srcSet={`${File}, ${File2x} 2x, ${File3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="cup"
          transition={{ ...transitionConfig }}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 2,
            top: '319px',
            left: '311px',
            y: CupMotion
          }}
        >
          <img alt="cup" src={Cup} srcSet={`${Cup}, ${Cup2x} 2x, ${Cup3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="mongle1"
          transition={{ ...transitionConfig }}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 1,
            top: '112px',
            left: '10px',
            y: Mongle1Motion
          }}
        >
          <img
            alt="mongle1"
            src={Mongle1}
            srcSet={`${Mongle1}, ${Mongle12x} 2x, ${Mongle13x} 3x`}
          />
        </MotionBox>
        <MotionBox
          key="mongle2"
          transition={{ ...transitionConfig }}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 1,
            top: '50px',
            right: '12px',
            y: Mongle2Motion
          }}
        >
          <img
            alt="mongle2"
            src={Mongle2}
            srcSet={`${Mongle2}, ${Mongle22x} 2x, ${Mongle23x} 3x`}
          />
        </MotionBox>
      </AnimatePresence>
    </Box>
  );
}
