import { Box, Text, Grid, Button } from '@insd47/graytools-library';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { MainBG, WhenBG, WhoBG } from 'src/assets/animations/Main';
import { ReactComponent as Small } from '../../assets/logos/small.svg';
import { initialMarker } from '../../utilities';
import Footer from './Footer';

// numbers
import NumberOne from '../../assets/illustrations/number1.png';
import NumberOne2x from '../../assets/illustrations/number1@2x.png';
import NumberOne3x from '../../assets/illustrations/number1@3x.png';
import NumberTwo from '../../assets/illustrations/number2.png';
import NumberTwo2x from '../../assets/illustrations/number2@2x.png';
import NumberTwo3x from '../../assets/illustrations/number2@3x.png';
import NumberThree from '../../assets/illustrations/number3.png';
import NumberThree2x from '../../assets/illustrations/number3@2x.png';
import NumberThree3x from '../../assets/illustrations/number3@3x.png';

// motion components
const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionGrid = motion(Grid);

function Desktop() {
  const {
    colors: { background }
  } = useTheme();

  return (
    <Box
      horizontal="center"
      style={{
        width: '100%'
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          backgroundColor: background.nav.main,
          width: '100%',
          height: 'min(90vh, 900px)'
        }}
      />
      <Section
        style={{
          position: 'relative',
          height: 'min(90vh, 900px)',
          maxHeight: 'unset',
          overflow: 'hidden',
          marginBottom: 100
        }}
      >
        <Grid
          columns="1fr 420px"
          style={{
            width: '100%',
            padding: '0 60px',
            height: '100%'
          }}
        >
          <MotionBox {...initialMarker(0)} vertical="center" style={{ height: '100%' }}>
            <Small style={{ marginBottom: '14px' }} />
            <Text size={48} line="66px" bottom={14} weight="heavy">
              언제,
              <br />
              어디서나,
              <br />
              누구나.
            </Text>
            <Button
              size="big"
              accent="accent"
              style={{ position: 'relative', paddingLeft: '30px', paddingRight: '30px', zIndex: 5 }}
            >
              소식 받기
            </Button>
          </MotionBox>
          <Box
            style={{
              position: 'relative',
              height: '75%',
              margin: 'auto 0'
            }}
          >
            <MainBG />
          </Box>
        </Grid>
      </Section>
      <Section>
        <MotionBox {...initialMarker(0)} horizontal="center">
          <img
            draggable="false"
            alt="1"
            src={NumberOne}
            srcSet={`${NumberOne}, ${NumberOne2x} 2x, ${NumberOne3x} 3x`}
            style={{ marginBottom: '10px' }}
          />

          <Text size={30} weight="heavy" bottom={36}>
            언제든지 유용하게 사용할 수 있는
          </Text>
        </MotionBox>
        <Grid
          columns="1fr 480px"
          columnGap="30px"
          style={{
            width: '100%',
            padding: '0 60px'
          }}
        >
          <Box vertical="center" style={{ height: '100%' }}>
            <MotionText
              {...initialMarker(0.2)}
              size={18}
              line={1.65}
              style={{ wordBreak: 'keep-all' }}
            >
              GrayTools는 언제든지 사용할 수 있도록 개발하고 있는 도구 모음입니다.친구 모임에서 팀을
              나눌 때, 두 물건 중 하나를 결정해야 할 때, 다른 기기로 파일을 보내고 싶을 때 등 수많은
              상황에서 쉽고 간편하게 활용할 수 있습니다.
            </MotionText>
            <MotionGrid
              {...initialMarker(0.6)}
              columns="auto 1fr"
              columnGap="22px"
              style={{
                width: '100%'
              }}
            >
              <Text size={80} weight="bold">
                11
              </Text>
              <Box vertical="center" style={{ height: '100%' }}>
                <Text size={18}>
                  <strong style={{ fontWeight: 500 }}>출시 시 기본 탑재 기능 수</strong>
                  <br />
                  기능은 더 추가됩니다!
                </Text>
              </Box>
            </MotionGrid>
          </Box>
          <MotionBox {...initialMarker(0.4)}>
            <WhenBG />
          </MotionBox>
        </Grid>
      </Section>
      <Section
        style={{
          justifyContent: 'unset',
          padding: '60px 0',
          height: 'unset',
          maxWidth: '100%'
        }}
      >
        <Box horizontal="center" style={{ marginBottom: '30px' }}>
          <MotionBox horizontal="center" {...initialMarker(0)}>
            <img
              draggable="false"
              alt="2"
              src={NumberTwo}
              srcSet={`${NumberTwo}, ${NumberTwo2x} 2x, ${NumberTwo3x} 3x`}
              style={{ marginBottom: '10px' }}
            />

            <Text size={30} weight="heavy" bottom={36}>
              어디서든 사용할 수 있는
            </Text>
          </MotionBox>
          <MotionText
            {...initialMarker(0.2)}
            size={18}
            line={1.65}
            style={{ wordBreak: 'keep-all', textAlign: 'center' }}
          >
            GrayTools는 웹 브라우저를 사용할 수 있는
            <br />
            모든 기기에서 이용할 수 있습니다.
          </MotionText>
        </Box>
        <WhereImage />
      </Section>
      <Section>
        <Box {...initialMarker(0)} horizontal="center">
          <img
            draggable="false"
            alt="3"
            src={NumberThree}
            srcSet={`${NumberThree}, ${NumberThree2x} 2x, ${NumberThree3x} 3x`}
            style={{ marginBottom: '10px' }}
          />

          <Text size={30} weight="heavy" bottom={36}>
            누구나 사용할 수 있는
          </Text>
        </Box>
        <Grid
          columns="1fr 480px"
          columnGap="30px"
          style={{
            width: '100%',
            padding: '0 60px'
          }}
        >
          <Box vertical="center" style={{ height: '100%' }}>
            <MotionText
              {...initialMarker(0.2)}
              size={18}
              line={1.65}
              style={{ wordBreak: 'keep-all' }}
            >
              GrayTools의 모든 도구는 무료로 제공되며,
              <br />
              더 많은 사람들이 이용할 수 있도록
              <br />
              쉽게 쓸 수 있는 인터페이스를 제공하고
              <br />
              도움말 페이지를 제공합니다.
              <br />
              <br />
              출시 시에는 한국어 및 영어를 지원하며,
              <br />
              이후 꾸준히 업데이트될 예정입니다.
            </MotionText>
          </Box>
          <Box>
            <WhoBG />
          </Box>
        </Grid>
      </Section>
      <Footer />
    </Box>
  );
}

export const Section = styled.section`
  height: 90vh;
  max-height: 780px;
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 140px;
`;

export const WhereImage = styled.div`
  height: 420px;
  width: 100%;
  background-size: auto 420px;
  background-image: image-set(
    url(/illustrations/where.png) 1x,
    url(/illustrations/where@2x.png) 2x,
    url(/illustrations/where@3x.png) 3x
  );

  animation-name: WhereImageDesktop;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  will-change: background-position-x;

  @keyframes WhereImageDesktop {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 1440px;
    }
  }
`;

export default Desktop;
