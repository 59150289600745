import { Box } from '@insd47/graytools-library';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowDimensions, initialMarker } from '../../../utilities';

import BG1 from '../../elements/Who/BG1.png';
import BG12x from '../../elements/Who/BG1@2x.png';
import BG13x from '../../elements/Who/BG1@3x.png';
import BG2 from '../../elements/Who/BG2.png';
import BG22x from '../../elements/Who/BG2@2x.png';
import BG23x from '../../elements/Who/BG2@3x.png';
import Board from '../../elements/Who/Board.png';
import Board2x from '../../elements/Who/Board@2x.png';
import Board3x from '../../elements/Who/Board@3x.png';
import Puzzle from '../../elements/Who/Puzzle.png';
import Puzzle2x from '../../elements/Who/Puzzle@2x.png';
import Puzzle3x from '../../elements/Who/Puzzle@3x.png';
import Ruler from '../../elements/Who/Ruler.png';
import Ruler2x from '../../elements/Who/Ruler@2x.png';
import Ruler3x from '../../elements/Who/Ruler@3x.png';
import Textfield from '../../elements/Who/Textfield.png';
import Textfield2x from '../../elements/Who/Textfield@2x.png';
import Textfield3x from '../../elements/Who/Textfield@3x.png';

// motion components
const MotionBox = motion(Box);

export default function WhenBG() {
  const { viewportWidth } = useWindowDimensions();
  const ElementScale = viewportWidth - 48 < 480 ? (viewportWidth - 48) / 480 : 1;

  return (
    <Box
      style={{
        width: '480px',
        height: '360px',
        margin: '0 auto',
        transformOrigin: 'top left',
        transform: `scale(${ElementScale})`
      }}
    >
      <AnimatePresence>
        <MotionBox
          key="bg1"
          {...initialMarker(0)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 2,
            left: '0',
            top: '0'
          }}
        >
          <img alt="bg1" src={BG1} srcSet={`${BG1}, ${BG12x} 2x, ${BG13x} 3x`} />
        </MotionBox>
        <MotionBox
          key="bg2"
          {...initialMarker(0.1)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 1,
            right: '0',
            bottom: '48px'
          }}
        >
          <img alt="bg2" src={BG2} srcSet={`${BG2}, ${BG22x} 2x, ${BG23x} 3x`} />
        </MotionBox>
        <MotionBox
          key="board"
          {...initialMarker(0.2)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 3,
            left: '15px',
            top: '102px'
          }}
        >
          <img alt="board" src={Board} srcSet={`${Board}, ${Board2x} 2x, ${Board3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="puzzle"
          {...initialMarker(0.4)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 5,
            left: '260px',
            top: '70px'
          }}
        >
          <img alt="puzzle" src={Puzzle} srcSet={`${Puzzle}, ${Puzzle2x} 2x, ${Puzzle3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="ruler"
          {...initialMarker(0.3)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            top: '249px',
            left: '173px',
            zIndex: 4
          }}
        >
          <img alt="ruler" src={Ruler} srcSet={`${Ruler}, ${Ruler2x} 2x, ${Ruler3x} 3x`} />
        </MotionBox>
        <MotionBox
          key="textfield"
          {...initialMarker(0.5)}
          style={{
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            willChange: 'transform',
            zIndex: 6,
            left: '28px',
            top: '78px'
          }}
        >
          <img
            alt="textfield"
            src={Textfield}
            srcSet={`${Textfield}, ${Textfield2x} 2x, ${Textfield3x} 3x`}
          />
        </MotionBox>
      </AnimatePresence>
    </Box>
  );
}
