import { useState, useEffect, useLayoutEffect } from 'react';

import Desktop from './Desktop';
import Mobile from './Mobile';

function Main() {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const defineMobile = () => {
    if (document.documentElement.clientWidth > 960) setIsMobile(false);
    else setIsMobile(true);
  };

  useLayoutEffect(() => {
    defineMobile();
  }, []);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', defineMobile);
    }

    return () => window?.removeEventListener('resize', defineMobile);
  }, []);

  return isMobile ? <Mobile /> : <Desktop />;
}

export default Main;
