import { useState, useEffect } from 'react';

interface WindowDimentions {
  viewportWidth: number;
  viewportHeight: number;
}

function getWindowDimensions(): WindowDimentions {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    viewportWidth: width,
    viewportHeight: height
  };
}

export default function useWindowDimensions(noChange?: boolean): WindowDimentions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>(getWindowDimensions());

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    if (!noChange) window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, [noChange]);

  return windowDimensions;
}
