import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box, Button, Text, Grid } from '@insd47/graytools-library';
import { ReactComponent as MyLogo } from '../../assets/logos/mylogo.svg';

export default function Footer() {
  const {
    colors: { primary }
  } = useTheme();
  return (
    <StyledFooter>
      <Box
        style={{
          maxWidth: '1000px',
          width: '100%',
          margin: '0 auto'
        }}
      >
        <Grid columns="auto 1fr" columnGap={18}>
          <Button
            size="pico"
            accent="transparent"
            round={6}
            style={{
              fontWeight: 400
            }}
          >
            <MyLogo />
          </Button>
          <Box style={{ margin: 'auto 0' }}>
            <Box horizontal="center" vertical="center" direction="row">
              <Text style={{ color: primary.text.alpha(0.6) }}>문의:</Text>
              <Text selectable style={{ color: primary.text.alpha(0.6), paddingLeft: '2px' }}>
                support@graytools.app
              </Text>
            </Box>
            <Text style={{ color: primary.text.alpha(0.6) }}>artworks by Nunsaram.</Text>
          </Box>
        </Grid>
      </Box>
    </StyledFooter>
  );
}

export const StyledFooter = styled(Box)`
  width: 100%;
  padding: 30px;

  ${({
    theme: {
      colors: {
        background: { nav }
      },
      rounds
    }
  }) => ({
    backgroundColor: nav.main,
    borderRadius: rounds.generic
  })};
`;
