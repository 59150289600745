const transitionConfig = { type: 'spring', stiffness: 300, damping: 30, mass: 0.2 };

const initialMarker = (delay: number) => ({
  viewport: {
    once: true
  },
  initial: { opacity: 0, y: 60 },
  whileInView: { opacity: 1, y: 0 },
  transition: {
    opacity: {
      duration: 0.3,
      ease: 'easeInOut',
      delay
    },
    y: { ...transitionConfig, delay }
  }
});

export default initialMarker;
