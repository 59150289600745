import { useEffect, useState } from 'react';
import { Box, Text, Button } from '@insd47/graytools-library';
import { useTheme } from '@emotion/react';
import { useScroll, motion, useTransform } from 'framer-motion';
import styled from '@emotion/styled';
import { useWindowDimensions, initialMarker } from 'src/utilities';
import { ReactComponent as Arrow } from '../../assets/elements/Arrow.svg';
import { ReactComponent as Small } from '../../assets/logos/small.svg';
import { MainBG, WhenBG, WhoBG } from '../../assets/animations/Main';
import Footer from './Footer';

// numbers
import NumberOne from '../../assets/illustrations/number1.png';
import NumberOne2x from '../../assets/illustrations/number1@2x.png';
import NumberOne3x from '../../assets/illustrations/number1@3x.png';
import NumberTwo from '../../assets/illustrations/number2.png';
import NumberTwo2x from '../../assets/illustrations/number2@2x.png';
import NumberTwo3x from '../../assets/illustrations/number2@3x.png';
import NumberThree from '../../assets/illustrations/number3.png';
import NumberThree2x from '../../assets/illustrations/number3@2x.png';
import NumberThree3x from '../../assets/illustrations/number3@3x.png';

// motion components
const MotionBox = motion(Box);
const MotionText = motion(Text);

function Mobile() {
  const {
    colors: { background }
  } = useTheme();
  const { viewportHeight, viewportWidth } = useWindowDimensions();

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const WhenContainerHeight = viewportWidth - 48 < 480 ? ((viewportWidth - 48) / 480) * 476 : 476;
  const WhoContainerHeight = viewportWidth - 48 < 480 ? ((viewportWidth - 48) / 480) * 360 : 360;

  const { scrollY } = useScroll();
  const TextMotion = useTransform(scrollY, [0, (viewportHeight / 100) * 94], [50, 0]);
  const TextOpacity = useTransform(scrollY, [20, (viewportHeight / 100) * 94 - 50], [1, 0]);

  useEffect(() => {
    const onScroll = () => {
      const scrollStatus = window.scrollY;

      if (scrollStatus > 20) setIsScrolled(true);
      else setIsScrolled(false);
    };

    if (window) {
      document.addEventListener('scroll', onScroll);
    }
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Box
      horizontal="center"
      style={{
        width: '100%',
        backgroundColor: background.nav.main
      }}
    >
      <Section
        style={{
          padding: '0 50px',
          height: '94vh',
          margin: '0',
          position: 'unset'
        }}
      >
        <MotionBox
          {...initialMarker(0)}
          horizontal="flex-start"
          style={{
            marginBottom: '60px',
            willChange: 'transform',
            y: TextMotion,
            // @ts-ignore : motionvalue is assignable to opacity prop
            opacity: TextOpacity
          }}
        >
          <Small style={{ marginBottom: '10px' }} />
          <Text
            style={{
              position: 'relative',
              zIndex: 10,
              textShadow: `0 0 40px ${background.nav.alpha(1)}`
            }}
            size={viewportWidth < 400 ? 38 : 48}
            line={viewportWidth < 400 ? '56px' : '66px'}
            weight="heavy"
            bottom={14}
          >
            언제,
            <br />
            어디서나,
            <br />
            누구나.
          </Text>
          <Button
            size="big"
            accent="accent"
            style={{ position: 'relative', paddingLeft: '30px', paddingRight: '30px', zIndex: 5 }}
          >
            소식 받기
          </Button>
        </MotionBox>
      </Section>
      <Box
        style={{
          position: 'fixed',
          height: '94vh',
          width: '100%',
          zIndex: 0,
          pointerEvents: 'none'
        }}
      >
        <MainBG />
      </Box>
      <Box
        style={{
          width: '100%',
          backgroundColor: background.bg.main,
          position: 'relative',
          zIndex: '5'
        }}
      >
        <Arrow
          style={{
            position: 'absolute',
            left: '50%',
            top: '6vh',
            transform: 'translate(-50%, -50%)',
            opacity: isScrolled ? 0 : 1,
            transition: 'opacity 0.2s'
          }}
        />
        <Section style={{ marginBottom: '100px' }}>
          <MotionBox {...initialMarker(0)} style={{ willChange: 'transform' }} horizontal="center">
            <img
              draggable="false"
              alt="1"
              src={NumberOne}
              srcSet={`${NumberOne}, ${NumberOne2x} 2x, ${NumberOne3x} 3x`}
              style={{ marginBottom: '10px' }}
            />
            <Text size={24} weight="heavy" bottom={18}>
              언제든지 유용하게 사용할 수 있는
            </Text>
          </MotionBox>
          <Box
            horizontal="center"
            style={{
              padding: '0 24px'
            }}
          >
            <Box vertical="center" style={{ height: '100%' }}>
              <MotionText
                {...initialMarker(0.2)}
                size={16}
                line={1.65}
                style={{
                  wordBreak: 'keep-all',
                  textAlign: 'center',
                  marginBottom: '20px',
                  maxWidth: '600px',
                  willChange: 'transform'
                }}
              >
                GrayTools는 언제든지 사용할 수 있도록 개발하고 있는 도구 모음입니다. 친구 모임에서
                팀을 나눌 때, 두 물건 중 하나를 결정해야 할 때, 다른 기기로 파일을 보내고 싶을 때 등
                수많은 상황에서 쉽고 간편하게 활용할 수 있습니다.
              </MotionText>
              <MotionBox
                {...initialMarker(0.6)}
                style={{
                  margin: '0 auto',
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  columnGap: '14px',
                  marginBottom: '60px',
                  position: 'relative',
                  willChange: 'transform'
                }}
              >
                <Text size={60} line={1} weight="bold">
                  11
                </Text>
                <Box vertical="center" direction="row" style={{ margin: 'auto 0' }}>
                  <Text size={16}>
                    <strong style={{ fontWeight: 500 }}>출시 시 기본 탑재 기능 수</strong>
                    <br />
                    기능은 더 추가됩니다!
                  </Text>
                </Box>
              </MotionBox>
            </Box>
          </Box>
          <MotionBox
            {...initialMarker(0.4)}
            style={{ padding: '0 24px', height: WhenContainerHeight }}
          >
            <WhenBG />
          </MotionBox>
        </Section>
        <Section
          style={{
            justifyContent: 'unset',
            padding: '0',
            height: 'unset',
            maxWidth: '100%'
          }}
        >
          <Box horizontal="center" style={{ marginBottom: '30px', padding: '0 24px' }}>
            <MotionBox
              style={{ willChange: 'transform' }}
              horizontal="center"
              {...initialMarker(0)}
            >
              <img
                draggable="false"
                alt="2"
                src={NumberTwo}
                srcSet={`${NumberTwo}, ${NumberTwo2x} 2x, ${NumberTwo3x} 3x`}
                style={{ marginBottom: '10px' }}
              />
              <Text size={24} weight="heavy" bottom={18}>
                어디서든 사용할 수 있는
              </Text>
            </MotionBox>
            <MotionText
              {...initialMarker(0.2)}
              size={16}
              line={1.65}
              style={{
                width: '100%',
                wordBreak: 'keep-all',
                textAlign: 'center',
                willChange: 'transform'
              }}
            >
              GrayTools는 웹 브라우저를 사용할 수 있는
              <br />
              모든 기기에서 이용할 수 있습니다.
            </MotionText>
          </Box>
          <WhereImage />
        </Section>
        <Section>
          <MotionBox style={{ willChange: 'transform' }} {...initialMarker(0)} horizontal="center">
            <img
              draggable="false"
              alt="3"
              src={NumberThree}
              srcSet={`${NumberThree}, ${NumberThree2x} 2x, ${NumberThree3x} 3x`}
              style={{ marginBottom: '10px' }}
            />

            <Text size={24} weight="heavy" bottom={18}>
              누구나 사용할 수 있는
            </Text>
          </MotionBox>
          <Box style={{ padding: '0 24px' }}>
            <Box vertical="center" style={{ width: '100%' }}>
              <MotionText
                {...initialMarker(0.2)}
                size={16}
                line={1.65}
                style={{
                  width: '100%',
                  wordBreak: 'keep-all',
                  textAlign: 'center',
                  marginBottom: '60px',
                  willChange: 'transform'
                }}
              >
                GrayTools의 모든 도구는 무료로 제공되며,
                <br />
                더 많은 사람들이 이용할 수 있도록
                <br />
                쉽게 쓸 수 있는 인터페이스를 제공하고
                <br />
                도움말 페이지를 제공합니다.
                <br />
                <br />
                출시 시에는 한국어 및 영어를 지원하며,
                <br />
                이후 꾸준히 업데이트될 예정입니다.
              </MotionText>
            </Box>
          </Box>
          <Box
            style={{
              width: '100%',
              padding: '0 24px',
              height: WhoContainerHeight
            }}
          >
            <WhoBG />
          </Box>
        </Section>
        <Footer />
      </Box>
    </Box>
  );
}

export const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 120px 0;
  margin-bottom: 140px;
`;

export const WhereImage = styled.div`
  height: 210px;
  background-size: auto 210px;
  width: 100%;
  background-image: image-set(
    url(/illustrations/where.png) 1x,
    url(/illustrations/where@2x.png) 2x,
    url(/illustrations/where@3x.png) 3x
  );
  animation-name: WhereImageMobile;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  will-change: background-position-x;

  @keyframes WhereImageMobile {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 720px;
    }
  }
`;

export default Mobile;
